<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Detalhes
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="data.name"
        label="Processo"
        outlined
        dense
        :append-icon="icons.mdiPencil"
      />

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoadingSendData"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      isLoadingSendData: false,

      icons: {
        mdiPencil,
      },
    }
  },

  methods: {
    async sendModalData() {
      const { data } = this

      const body = {
        name: data.name,
      }

      this.isLoadingSendData = true

      await axiosIns.put(`/api/v1/sgq/type/update/${data.id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoadingSendData = false
          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.processIdSelected = ''
      this.occupationIdSelected = ''
    },
  },
}
</script>
