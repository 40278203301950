<template>
  <div>
    <v-card
      class="mb-6"
    >
      <v-card-title class="pt-0 pb-0">
        <span>
          Tipos de documentos
        </span>

        <v-spacer />
        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-7 mr-4"
          label="Buscar por Nome"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          color="info"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoadingTableData"
        loading-text="Carregando dados..."
      >
        <template
          v-slot:no-data
        >
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.edit="{item}">
          <v-icon
            medium
            class="me-2"
            color="info"
            @click="openModal('details',item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{item}">
          <v-icon
            medium
            class="me-2"
            color="error"
            @click="deleteRegister(endpointDelete, item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>

        <template v-slot:item.updated_at="{ item }">
          {{ !item.created_at ? '' : dateFormat(item.created_at) }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ !item.created_at ? '' : dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showDocumentTypeModal"
      width="500px"
    >
      <DocumentTypeModal
        @updatedTable="updatedTable()"
        @close="showDocumentTypeModal = false"
      ></DocumentTypeModal>
    </v-dialog>

    <v-dialog
      v-model="showDocumentTypeDetails"
      width="500px"
    >
      <DocumentTypeDetails
        :key="documentTypeDetails.id"
        :data="documentTypeDetails"
        @updatedTable="updatedTable()"
        @close="showDocumentTypeDetails = false"
      ></DocumentTypeDetails>
    </v-dialog>
  </div>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import DocumentTypeDetails from './DocumentTypeDetails.vue'
import DocumentTypeModal from './DocumentTypeModal.vue'

export default {
  components: {
    DocumentTypeModal,
    DocumentTypeDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',
      endpointDelete: '/api/v1/sgq/type/destroy',

      processesList: [],
      occupationList: [],
      documentTypeDetails: [],

      headers: [
        {
          text: 'NOME', value: 'name', sortable: false, align: 'left',
        },
        {
          text: 'CRIADO EM', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'ATUALIZADO EM', value: 'updated_at', sortable: false, align: 'center',
        },
        {
          text: 'EDITAR', value: 'edit', sortable: false, align: 'center',
        },
        {
          text: 'DELETAR', value: 'delete', sortable: false, align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoadingTableData: false,
      showDocumentTypeModal: false,
      showDocumentTypeDetails: false,

      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.isLoadingTableData = true

      await axiosIns.get('/api/v1/sgq/type/index').then(
        response => {
          const { data } = response.data

          this.listOfFilteredItems = data
          this.itemsTable = data
        },
      ).catch(error => { this.showErrorMessage(error) })
        .finally(() => { this.isLoadingTableData = false })
    },

    findFilterResult() {
      const { filterData, itemsTable } = this
      const filter = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        ({
          name,
        }) => name.toLowerCase().includes(filter),

      )
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => {
          this.showDocumentTypeModal = true
        },
        details: () => {
          this.showDocumentTypeDetails = true
        },
      }

      if (type in modal) {
        modal[type]()
        this.documentTypeDetails = data

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },

  },
}
</script>
