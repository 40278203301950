<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar novo
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="nameOfDocumentType"
        label="Tipo de documento"
        outlined
        dense
      />

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoadingSendData"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      nameOfDocumentType: '',
      isLoadingSendData: false,
    }
  },

  methods: {
    async sendModalData() {
      const { nameOfDocumentType } = this
      const body = {
        name: nameOfDocumentType.toUpperCase(),
      }

      this.isLoadingSendData = true

      await axiosIns.post('/api/v1/sgq/type/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoadingSendData = false
          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.nameOfDocumentType = ''
    },
  },
}
</script>
